.file-container {
    height: 72px;
    width: 100%;
    border: 1px solid #D1D5DB;
    background-color: #F9FAFB;
    padding: 19px 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: space-between;
    margin-bottom: 13px;
}

.file-text {
    color: #374151;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* margin-left: 0px;
    width: 200px; */
    margin-bottom: -15px;
}

.file-text-size {
    color: #6B7280;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
}

.file-items {
   display: flex;
   align-items: center;
} 

.file-text-group {
      width: 250px;
       /* width: 300px; */
       margin-left: 16px;
}