.claim-info {
  width: 50%;
}

.claim-type {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #1F2937;
  margin: 0;
  margin-bottom: 16px;
}

.claim-policy-number {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  color: #1F2937;
  margin: 0;
  margin-bottom: 12px;
}

.claim-actions {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  align-items: flex-end;
  width: 50%;
}

.p-mr-12 {
  margin-right: 12px;
}
