button {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F2937;
  white-space: nowrap;

  height: 40px;
  min-width: 125px;
  /* min-width: 125px; */
  /* padding: 12px 16px; */
  border: none;
  border-radius: 6px;
  background-color: #e5e7eb;
  cursor: pointer;
}