.content-info {
  display: flex;
  height: 32px;
  min-width: fit-content;

  border: 1px solid #D1D5DB;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
}

.top-down {
  flex-direction: column;
  height: auto;
}

.content-info .content-count {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #e5e7eb;
  border-right: 1px solid #D1D5DB;
}

.content-info p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #353B40;

  margin: 0;
}

.content-info .content-status {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.content-status-default-bg {
  background-color: #fff;
}

.content-status-claims-status-bg {
  background-color: #f7fee7;
}

.content-info .info-pipe {
  width: 0px;
  height: 20px;
  background-color: #B3BDC6;
  border: 2px solid #B3BDC6;
  border-radius: 5px;
  margin: 0 24px;
  padding: 0;
}
