.theft-claim__container {
    display: flex;
    flex-direction: column;
}

.theft-claim__label {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;

    color: #6B7280;
    margin: 8px 0px;
}

.theft-claim__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    color: #374151;
}

.theft-claim__field-group{
    margin-bottom: 32px;
}