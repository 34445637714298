.header-profile-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 48px;
}

.header-profile-info {
    height: 34px;
    margin-left: 16px;
}

.header-profile-name {
    font-size: 16px;
    font-family: Roboto;
    margin: 0 !important;
    color: #1F2937;
    font-weight: 500;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-profile-role {
    font-size: 14px;
    font-family: Roboto;
    margin: 0 !important;
    color: #6B7280;
}

.header-profile-dropdown {
    margin-left: 28.5px;
}

.header-profile-icon{
    height: 40px;
    width: 40px;
    background-color: #e5e7eb;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}