.sidebar__menu-container {
    height: 100%;
    width: 40%;

    padding: 0 2rem 0 4rem;
    border-right: 1px solid #D1D5DB;

    border-radius: 16px 0px 0px 16px;

    background: #F3F4F6;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sidebar__items {
    margin-top: 1rem;
}

.sidebar__item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 11px;
}

.sidebar__title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;

    color: #1F2937;
    margin: 0;
}

.sidebar__intructions {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;

    color: #6B7280;

    margin: 8px 0 54px 0;
}

.sidebar__step-status {
    width: 30px;
    height: 30px;

    background: rgba(75, 85, 99, 0.1);
    border: 1px solid #1F2937;
    border-radius: 1000px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sidebar__step-details {
    margin-left: 15px;
}

.sidebar-step {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;

    color: #374151;
    margin: 0;
}

.sidebar__step-name {
    margin: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    color: #374151;
}

.sidebar__stepper{
    width: 0;
    height: 56.07px;
    border: 1px dashed #9CA3AF;

    margin-left: 14px;
    margin-bottom: 11px;
}

.sidebar__active-icon{
    width: 29px;
    height: 29px;
}