
.icon {
    padding-left: 16.5px;
    padding-top: 10px;
    min-width: 40px;
    position: absolute;
}

.inputfield {
    height: 40px;
    /* width: 477px; */
    /* width: 420px; */
    width: 100%;
    border-bottom: 1px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    /* padding-left: 48px; */
    padding-left: 16px;
    font-size: 14px;
    font-family: Roboto;
    color: #6B7280;
    background-color: #F9FAFB;
}

.has-icon {
  padding-left: 48px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6B7280;
    opacity: 1; /* Firefox */
    font-size: 14px;
    font-family: Roboto;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #6B7280;
    font-size: 14px;
    font-family: Roboto;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #6B7280;
    font-size: 14px;
    font-family: Roboto;
  }