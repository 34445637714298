.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;

  height: 24px;
  width: 60px;
  background: #FFFFFF;
  border: 1px solid #D1D5DB;
  border-radius: 24px;

  cursor: pointer;
}

/* .switch-container *:last-child {
  margin-left: 8px;
} */

.switch {
  height: 16px;
  width: 16px;
  background: #9CA3AF;
  border-radius: 15px;
  z-index: 1;
  transition: all .3s linear;
}

.switch.switch-on {
  background: #1F2937;;
  transform: translateX(210%);
}

.switch-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  transition: all .3s linear;
}

.switch-text.switch-on {
  transform: translateX(-100%);
}

.first {
  visibility: hidden;
  transition: all;
}

.last {
  visibility: visible;
}

.first.switch-on {
  visibility: hidden;
}

.last.switch-on {
  visibility: visible;
}
