.issues-and-conditions__wrapper {
  width: 100%;
  margin-bottom: 50px;
}

.issues-and-conditions__details {
  height: 267px;
  overflow: auto;
  padding-right: 0.45rem;
}

.issues-and-conditions__details::-webkit-scrollbar {
  width: 0.4rem;
  height: inherit;
}

.issues-and-conditions__details::-webkit-scrollbar-thumb {
  background-color: rgb(211, 217, 222, .4);
  border-radius: 0.5rem;
  margin-left: 3rem;
}