.checkbox-container {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid #9CA3AF;
  border-radius: 2px;
  cursor: pointer;
}

.checked {
  background: #E5E7EB;
  border: 2px solid #1F2937;
}