.theft-claim__container{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.theft-claim__section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 32px;
}

.theft-claim__section-fw{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.theft-claim__group{
    width: 48%;
}

.theft-datepicker{
    height: 40px;
    width: 100%;
    border-bottom: 1px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 14px;
    font-family: Roboto;
    color: #6B7280;
    background-color: #F9FAFB;

    /* background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat right; */
}

.theft-details-dropdown{
    margin-left: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: #F9FAFB;
}