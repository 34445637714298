.physical-damage-claim__container {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.physical-damage-claim__label {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;

  color: #6B7280;
  margin: 8px 0px;
}

.physical-damage-claim__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #374151;
  margin: 0;
  margin-bottom: 30px;
}

.physical-damage-claim__field-group {
  width: 100%;
  margin-bottom: 32px;
}

.physical-damage-claim__field-group input {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #374151;
}

.claim-input-field {
  display: flex;
  
}

.mr-32 {
  margin-right: 32px;
 
}
