.statement__container {
    width: 100%;
}

.statement__text {
    height: 120px;
    width: 100%;
    border-bottom: 1px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 14px;
    font-family: Roboto;
    color: #6B7280;
    background-color: #F9FAFB;

    resize: none;
}