.table {
    width: 100%;
    border: none;
    border-collapse: collapse;
    border-radius: 4px;
    margin-bottom: 32px;
}

th, td {
    padding-left: 24px;
}

.table-head {
    background-color: #E5E7EB;
    text-align: left;
    height: 34px;
    
}

.table-head-title {
    font-size: 12px;
    font-weight: 500;
    color: #6E7B87;
}

.table-row {
    height: 77px;
    background-color: #FFFFFF;
    border-top: 1px solid #E5E7EB;
}

.table-row:hover {
    background-color: #F9FAFB;
    border: 4px solid #374151;
    border-style: solid none;
}

.table-row .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
}

.table-row.checked {
    background-color: #F9FAFB;
    border: 4px solid #374151;
    border-style: solid none;
}

.table-row .ellipsis-btn * {
    visibility: hidden;
}

.table-row:hover .ellipsis-btn * {
    visibility: inherit;
}

.table-data-top {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #1F2937;
}

.table-data-bottom {
    font-size: 14px;
    color: #6B7280;
    margin-top: -13px;
}

.disabled-ellipsis-btn {
    color: #6B7280 !important;
}
