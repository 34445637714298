.p0 {
  padding: 0;
  margin: 0;
}

.edit-policy_header {
  display: inline-flex;
  width: 400px;
  height: 70px;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 24px;
}

.edit-policy_header > p.header {
  color: #1F2937;
  font-size: 40px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 40px;
  word-wrap: break-word
}

.edit-policy_header > p.sub-text {
  color: #6B7280;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word
}

.edit-policy_edit-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

hr.edit-policy_hr {
  border: 0;
  clear: both;
  height: 1px;
  background-color: #D1D5DB;
  margin: 0;
  margin-bottom: 64px;
}

/* ----- Edit details ----- */
.edit-policy_edit-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-policy_edit-details:last-child {
  margin-bottom: 60px;
}

.edit-policy_edit-details > .heading {
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 330px;
}

.heading > p.header {
  color: black;
  font-size: 24px;
  font-family: 'Roboto';
  font-weight: 500;
  word-wrap: break-word;
}

.heading > p.sub-text {
  color: #6B7280;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 400;
  word-wrap: break-word;
}


.edit-policy_edit-details > .content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 580px;
}

.edit-policy_label {
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 500;
  word-wrap: break-word;
  color: #6B7280;
  margin: 8px 0px;
}

.edit-policy_field-group {
  width: 100%;
}

.edit-policy_readonly {
  background-color: #F3F4F6;
}

.disabled-edit-input {
  color: #9CA3AF;
  background-color: #D9D9D9;
}