.customer-and-device-container {
  display: flex;
  justify-content: space-between;
}

.customer-and-device-heading {
  background: #E5E7EB;
  border-radius: 6px;
  padding: 8px 24px;
  margin-bottom: 24px;
}

.customer-and-device-heading p {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #1F2937;
  margin: 0;
}

.policy-customer-info, .policy-device-info {
 width: 48%
}

.policy-details {
  display: flex;
  flex-wrap: wrap;
  margin: 24px;
}

.policy-details > div {
  margin-right: 32px;
  margin-bottom: 32px;
}

.policy-details .label {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #6B7280;
  white-space: nowrap;

  margin-bottom: 8px;
}

.policy-details .info {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F2937;
  white-space: nowrap;
}
