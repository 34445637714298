/* Most styles for this table are in ./src/components/Content/Policies/Table/Table.css */

.table-data-top__w-400 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #1F2937;
  }

.table-header{
    text-wrap: nowrap;
}

.action-btn {
  background-color: #65A20C;
  color: white;
  padding: 5px; 
  min-width: 60px;
  height: auto;
  font-size: x-small;
}