.inactive-policy-modal__container {
  height: 416px;
  width: 558px;
  background-color: #FFF;
  padding: 64px;
  border-radius: 16px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inactive-policy-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inactive-policy-modal__content > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  /* width: 112px;
  height: 112px; */
  border: 1px solid #E5E7EB;
  border-radius: 200px;
  margin-bottom: 24px;
}

.inactive-policy-modal__content > span {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  color: #111827;
  margin-bottom: 16px;
}

.inactive-policy-modal__content > p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #6B7280;
  margin: 0;
}