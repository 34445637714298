.claim-details__container{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.claim-details__content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 60%;
    background: #fff;
    padding: 64px 48px 48px 48px;
    border-radius: 0px 16px 16px 0px;
}

.claim-details__footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-top: 1px solid #D1D5DB;

    padding-top: 24px;
}

.claim-details__next-btn{
    background-color: #65A30D;
    color: #fff;
}