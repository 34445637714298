.comments-wrapper {
    width: 100%;
    margin-bottom: 50px;
}

.comments-section {
    background: #E5E7EB;
    border-radius: 6px;
    padding: 8px 24px;
}

.comments {
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 24px;
}

.comments-section p {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #1F2937;
    margin: 0;
}

.comments__details {
    margin-top: 24px;
    padding: 0 24px;
}

.commenter, .current-status {
    font-weight: 600;
    text-transform: capitalize;
}

.comment-time {
    color: #65A30D;
}

.comment-header {
    display: flex;
    column-gap: 12px;
    align-items: center;
}

.comment-container {
    border-bottom: 1px solid #D1D5DB;
    margin-bottom: 24px;
}

.comment-header h4 {
    margin: 10px 0;
}

.claim-comments {
    margin-top: 24px;
}

.add-comment {
    width: 100%;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    overflow: hidden;
}

.user-comment {
    width: 100%;
    border: none;
    resize: none;
    padding: 24px;
}

.add-comment-btn {
    background-color: #65A30D;
    color: white;
}

.comment-action-seperator {
    margin: 0 24px !important;
    height: 1px !important;
    background-color: #D1D5DB !important;
}

.comment-actions {
    padding: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
}

.comment-status {
    display: flex;
    margin-top: 16px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    overflow: hidden;
    justify-content: space-between;
    max-width: fit-content;
}

.status-label {
    border-right: 1px solid #D1D5DB;
    padding-right: 6px 12px;
    background-color: #f3f4f6;
    cursor: pointer;
}

.active {
    background-color: #f7fee7;
    color: #6a9234;
}

.status-label p {
    margin: 0;
    padding: 8px;
    display: flex;
    column-gap: 8px;
    align-items: center;
}

.status-label:last-child {
    border-right: 0;
    padding-right: 12px;
}
