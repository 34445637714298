.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px;
  width: auto;
}

.menu-container a {
  display: flex;
  align-items: center;
  width: 190px;
  height: 64px;
  padding-left: 32px;
  text-decoration: none;
  margin-bottom: 30px;

  border-radius: 8px;
}

.menu-container svg {
  margin-right: 16px;
}

.menu-title {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #9CA3AF;
}

.menu-title .active {
  color: #fff;
}

.nav-active {
  background-color: #65A30D;
}

.nav-active p {
  color: #fff;
}