.action-footer-container {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid #D1D5DB;
  margin-top: auto;

  /* position: absolute;
  right: 0;
  bottom: 0;
  left: 0; */
}

.leading-actions button {
  margin-right: 12px;
}

.trailing-actions button {
 margin-left: 12px;
}

/* Add class disabled to the classes prop of any Button being passed into
   ActionFooter leading or trailing lists to have a disabled look */
.action-footer-container .disabled {
  color: #9CA3AF;
  background-color: #D1D5DB;
}
