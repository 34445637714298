#form-file-upload {
  height: 18rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 8px;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #F9FAFB;
}

.upload-button {
  /* width: 54px; */
  min-width: 54px;
  height: 24px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 12px;
  background-color: transparent;
}

.upload-button p {
  margin: 0;
}

.file-upload-header__p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #6B7280;
}

.file-upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.policy-claims__no-claims-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #4B5563;

  /* margin-top: 16px; */
}

.supporting-doc-text {
  color: #374151;
  font-weight: 600;
  font-family: 'Roboto';
  line-height: 16px;
  text-decoration: underline;
  cursor: default;
  margin-top: -5px;
}

#drag-file-element {
  height: 300px;
  width: 500px;
 
}