.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.footer-container p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  color: #6B7280;
}

p strong {
  color: #4B5563;
}

.pag {
  display: flex;
  list-style: none;
}

.pag li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  padding: 0 4px;
  margin: 0 4px;
  cursor: pointer;
}

.pag-active {
  color: #fff;
  background: #4B5563;
  border-radius: 4px;
}
