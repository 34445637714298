.content-ctrl-container {
  display: flex;
  justify-content: space-between;
  height: max-content;
  margin-bottom: 32px;
  
}

.content-ctrl-container .controls {
  display: flex;
}

.content-controls_content-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 8px 12px;
  background-color: #e5e7eb;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  box-sizing: border-box;
}

.content-controls_content-info2{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  margin-left:10px;
  margin-right: -12px;
}

.content-controls_content-info p {
  padding: 0;
  margin: 0;
}

.divider{
  height: 18px;
  width: 3px;
  background-color: #D1D5DB;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3px;
}