.policy-container {
  display: flex;
  flex-direction: column;
  padding: 48px;
  width: 100%;
  background-color: #fff;
  position: relative;
}

.policy-container hr {
  border: 0;
  clear:both;
  height: 1px;
  background-color: #D1D5DB;
  margin: 24px 0;
}

.policy_claim-table-loader {
  display: flex;
  justify-content: center;
}