.loading-spinner-container {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-spinner-container p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #6E7B87;
  margin-top: 24px;
}

.loading-spinner {
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
