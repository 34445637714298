.dropdown {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 8px 12px;
  background-color: #fff;
  margin-left: 16px;
  cursor: pointer;
 z-index: 1;
  position: relative; 
  /* hello */

  border: 1px solid #D1D5DB;
  border-radius: 4px;
}

.dropdown div {
  display: flex;
}

.dropdown p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #353b40;

  margin: 0;
  margin-right: 20px;
}

.dropdown svg {
  transition: all 0.4s;
}

.dropdown svg.rotate-arrow {
  transform: rotate(180deg);
  transition: all 0.4s;
}

.dropdown-area {
  display: flex;
  flex-direction: column;
  min-width: -webkit-fill-available;
  position: absolute;
  top: 40px;
  right: 0;
  padding: 24px 16px;
  background: #1F2937;
  box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  cursor: auto;
}

.dropdown-area button {
  display: flex;
  justify-content: start;
  align-items: center;
  color: #fff;
  background-color: transparent;
  border-radius: 8px;
  margin-bottom: 8px;
  width: 100%;

  font-weight: 400;
}

.dropdown-area button:hover {
  background-color: #374151;
}

.dropdown-area button.selected {
  background-color: #374151;
}