.device-condtitions-container {
  display: flex;
  margin-bottom: 64px;
}

.device-condtitions-heading {
  background: #E5E7EB;
  border-radius: 6px;
  padding: 8px 24px;
  /* margin-bottom: 24px; */
}

.device-condtitions-heading p {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #1F2937;
  margin: 0;
}

.device-condtitions-info, .device-issues-info {
 width: 100%
}

.policy-details {
  display: flex;
  flex-direction: row;
  margin: 24px;
}

.device-condtitions-details > div {
  margin-right: 32px;
  margin-bottom: 32px;
}

.device-condtitions-details .label {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #6B7280;
  white-space: nowrap;

  margin-bottom: 8px;
}

.device-condtitions-details .info {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F2937;
  white-space: nowrap;
}

.device-conditions-table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-radius: 4px;
  overflow-y: hidden;
  /* margin-bottom: 32px; */
}

.device-conditions-table-row {
  height: 77px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E5E7EB;
}

.device-condtitions-table-data {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top: 19px;
}

.supporting-doc-button {
  background-color: transparent;
  border: 1px solid #D1D5DB;
  min-width: 87px;
  height: 30px;
  font-size: 14px;
  color: #1F2937;
  padding: 0;
  margin-right: 24px;
}

.supporting-doc__title {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F2937;
  margin: 0;
}
