.breadcrumbs-container {
  display: flex;
  align-items: center;
  width: -1px;
  height: 46px;
  width: 100%;
  padding: 8px 48px;
  background-color: #fff;
  border-bottom: 1px solid #D1D5DB;
}

.breadcrumb-pipe {
  height: 30px;
  width: 1px;
  background-color: #D1D5DB;
  margin: 0 24px;
}

.breadcrumbs {
  display: flex;
}

.breadcrumb-back {
  cursor: pointer;
}

.breadcrumb-back svg {
  margin-right: 8px;
}

.crumb-separator svg {
  margin: 0 12.5px;
  transform: rotate(270deg);
}