.default-layout-container {
    display: flex;
    flex-direction: row;
}

.default-layout-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: #F9FAFB;
    margin-left: 280px; /* same width as side menu */
}