.physical-damage-claim__issues-list-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.physical-damage-claim__issues-list {
  height: 100%;
  overflow: scroll;
  margin-bottom: 24px;
  padding-right: 0.45rem;
}

.physical-damage-claim__issues-list::-webkit-scrollbar {
  width: 0.4rem;
  height: inherit;
}

.physical-damage-claim__issues-list::-webkit-scrollbar-thumb {
  background-color: rgb(211, 217, 222, .4);
  border-radius: 0.5rem;
  margin-left: 3rem;
}

.physical-damage-claim__issue {
  display: flex;
  align-items: center;
  padding: 16px;
  height: 48px;
  width: 100%;
  background: #F3F4F6;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  margin-bottom: 12px;
}

.physical-damage-claim__issue p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #1F2937;
  margin-left: 16px;
}

.physical-damage-claim__issue-footer {
  display: flex;
  justify-content: space-between;
}

.physical-damage-claim__issue-footer p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #6B7280;
}

.physical-damage-claim__issue-footer p:last-child {
  text-decoration: underline;
  cursor: pointer;
}

.list-shadow-bottom {
  height: 1rem;
  width: 100%;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  position: absolute;
  z-index: 1;
}
