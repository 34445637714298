.login__wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* padding: 0 25rem 4rem 25rem; */

    background: linear-gradient(180deg, #F7FAF4 57.5%, rgba(247, 250, 244, 0.03) 95.6%);
}

.login__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    width: 100%;
    height: 100%;

    /* padding: 0 5.094rem; */

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.49) 43.23%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(0px -120px 8px rgba(167, 161, 161, 0.15));
}

.login__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__icon-container {
    width: 9.25rem;
    height: 19rem;
    background: #1F2937;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 100px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login__header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #6B7280;
    margin: 32px 0;
}

.login__form-container {
    /* width: 100%; */
    width: 573px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.login__btn {
    height: 68px;
    /* width: 360px;  */
    width: 477px;
    background: #65A30D;
    box-shadow: 0px 4px 7px rgba(109, 160, 35, 0.41);
    border-radius: 6px;
    color: #fff;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

.login__forgot-pw {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;

    color: #6B7280;
    margin-top: 28px;

    text-decoration: none;
}

.login__copyright-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 360px; 
    color: #6E7B87;
    margin: 2rem 0rem 2rem 0rem;
}

.login__background {
    height: 100%;
    width: 100%;

    position: absolute;
    z-index: -1;
    bottom: 0;

    background-image: url('/src/assets/svg/loginPage/login-bg.svg');
}

.login__icon-input {
    display: flex;
    flex-direction: row-reverse;
}

.icon__padding {
    padding-left: 24px !important;
}

.icon__style {
    padding-top: 23px !important;
    margin-right: 24px;
    cursor: pointer;
}

.login__error {
    height: 40px;
    /* width: 100%; */
    width: 477px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    margin-bottom: 32px;

    background: #FEE2E2;
    border: 1px solid #B91C1C;
    box-shadow: 0px 4px 8px rgba(160, 1, 1, 0.17), 0px 0px 0px 2px rgba(185, 28, 28, 0.1);
    border-radius: 8px;
}

.login__error-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #B91C1C;
    margin: 0;
}

.login__close-icon{
    cursor: pointer;
}