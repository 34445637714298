.header-container {
    min-height: 80px;
    width: 100%;
    background-color: #FFF;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.header-left-group {
  display: flex;
  flex-direction: row;
}

.header-searchbar {
  margin-left: 48px;
}