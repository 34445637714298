.default-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgb(31, 41, 55, .7);
    z-index: 20;
  }

.quick-actions__container{
    height: 88%;
    width: 65%;

    border-radius: 16px;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* position: absolute; */
    position: fixed;
    z-index: 5;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #F3F4F6;;
}

.modal-content__container{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quick-action__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.quick-actions__select-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 100%;

    padding: 0 8rem;
}