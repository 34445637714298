.claim-type__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.claim-type__header {
    font-weight: 700;
    font-size: 28px;
    line-height: 10px;
    text-align: center;

    color: #000000;
    margin: 0 0 4.8rem 0;
}

.claim-type__btn {
    width: 30%;
    height: 19rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #E5E7EB;
    border: 1.25556px solid rgba(82, 82, 91, 0.5);
    border-radius: 5.02222px;

    cursor: pointer;
}

.claim-type__icon-container {
    width: 7.5rem;
    height: 7.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 4px solid #65A30D;
    border-radius: 3.67568px;
}

.selected-btn {
    border: 1.25556px solid #65A30D;
    background-color: #F7FEE7;
}

.selected-btn-icon{
    background: #1F2937;
    border: none;
}

.claim-type__container {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;
}

.claim-type__text {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    text-align: center;

    color: #302D2A;

    margin: 24px 0px 0px 0px;
}