.physical-damage-claim__condition {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    height: 48px;
    width: 100%;
    background: #F3F4F6;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  
  .supporting-doc-claim__title {
    margin: 0;
    margin-bottom: 25px;
  }

  .supporting-doc-title-bold {
    color: #1F2937
  }
  .supporting-doc-sub-text {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #4B5563;
    
    /* margin-top: 16px; */
  }

  .supporting-doc-sub-text {
    color: #65A30D;
    font-weight: 600;
    font-family: 'Roboto';
    line-height: 16px;
    text-decoration: underline;
    cursor: default;
    margin-top: -5px;
  }