@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ---------- Button styles ---------- */
.p-btn {
  margin-left: 16px;
}

.sms-btn {
  color: #fff;
  background-color: #65a30d;
}
/* ---------- End of Button styles ---------- */

/* ---------- Text styles ---------- */
.sms-text {
  color: #65a30d;
}

.red-text {
  color: #B91C1C;
}
/* ---------- End of Text styles ---------- */

/* ---------- Status styles ---------- */
.active {
  color: #4D7C07;
  border: 1px solid #4D7C0F;
}

.inactive {
  color: #B91C1C;
  border: 1px solid #B91C1C;
}
/* ---------- End of Status styles ---------- */

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
}
