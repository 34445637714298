.policy-info {
  width: 50%;
}

.policy-number {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
  color: #1F2937;
  margin: 0;
  margin-bottom: 16px;
}

.policy-actions {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  width: 50%;
}

.p-mr-12 {
  margin-right: 12px;
}

.policy-dates p {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #6B7280;
  margin: 0;
}

.policy-dates {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
