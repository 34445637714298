.toaster-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 60px;
  min-width: 350px;
  background: #FFF;
  border: 2px solid #6B7280;
  box-shadow: 0px 4px 10px rgb(107, 114, 128, 0.3), 0px 0px 0px 2px rgb(107, 114, 128, 0.1);
  border-radius: 12px;
  white-space: nowrap;
}

.toaster-container p {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.toaster-container .toaster-content {
  display: flex;
  align-items: center;
}

.toaster-container .toaster-content svg {
  margin-right: 8px;
}

.toaster-container .cls-icon {
  cursor: pointer;
  margin-left: 25px;
}

/* ---------- Success styles ---------- */
.toaster-container.success { 
  background: #F7FEE7;
  border: 2px solid #65A30D;
  box-shadow: 0px 4px 10px rgba(101, 163, 13, 0.3), 0px 0px 0px 2px rgba(101, 163, 13, 0.1);
}

.toaster-container.success p {
  color: #65A30D;
}
/* ---------- End of success styles ---------- */

/* ---------- Error styles ---------- */
.toaster-container.error { 
  background: #FFECE6;
  border: 2px solid #A50D0D;
  box-shadow: 0px 4px 10px rgba(248, 128, 120, 0.4), 0px 0px 0px 2px rgba(232, 51, 50, 0.2);
}

.toaster-container.error p {
  color: #A50D0D;
}
/* ---------- End of error styles ---------- */