.status-container {
    display: flex;
    justify-Content: center;
    align-Items: center;
    height: 20px;
    max-width: fit-content;
    padding: 0 4px;
    border-radius: 4px;
}

.status-title {
    font-size: 12px;
    text-transform: capitalize;
}