.create-user-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 683px;
  height: 600px;
  padding: 48px;
  background: #FFFFFF;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
}

.create-user-modal__form {
  display: flex;
  flex-direction: column;
}

.create-user-modal__heading {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1F2937;
  margin: 0;
  margin-bottom: 8px;
}

.create-user-modal__subheading {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #6B7280;
  margin: 0;
  margin-bottom: 48px;
}

.create-user-modal__group {
  display: flex;
  margin-bottom: 40px;
}

.create-user-modal__field-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.create-user-modal__field-group:first-child {
  margin-right: 24px;
}

.create-user-modal__label {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #6B7280;
  margin: 0;
  margin-bottom: 8px;
}

.create-user-modal__actions {
  display: flex;
  justify-content: space-between;
}

.create-user-modal__dropdown{
  display: flex;
  justify-content: space-between;
  background-color: #F9FAFB;
  margin-left: 0;

  height: 40px;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding-left: 16px;
  
  font-size: 14px;
  font-family: Roboto;
  color: #6B7280;
}

.create-user-modal__error-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #DC2626;
  margin: 0;
  margin-top: 8px;
}