.container {
  width: 280px;
  height: 100vh;
  background-color: #1f2937;
  position: fixed;
}

.logo-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.sidebar__icon-container {
  width: 9.25rem;
  height: 19rem;
  background: #374151;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 100px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}