.content-header-container {
  display: flex;
  justify-content: space-between;
  height: max-content;
  margin-bottom: 32px;
}

.content-header-container p {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #1F2937;

  margin: 0;
}
